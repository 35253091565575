import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Home from './pages/home'
import Fabrication from './pages/fabrication'
import Macarons from './pages/macarons'
import Gallerie from './pages/gallerie'
import MacaronIA from './pages/macaron-ia'

const App = () => {
	return (
		<Router>
            <Routes>
                <Route path="/" element={<Home />} />
				<Route path="/fabrication" element={<Fabrication />} />
				<Route path='/macarons/:anchor' element={<Macarons />} />
				<Route path='/gallerie' element={<Gallerie />} />
				<Route path='/macaron-ia' element={<MacaronIA />} />
            </Routes>
    	</Router>
	)
}

export default App;
