// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/birdsofparadise.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/avenirnextcondensed.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/fonts/AbrahamLincoln.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: 'Birds';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

@font-face {
	font-family: 'Avenir';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

@font-face {
	font-family: 'Abraham';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

b {
	color: #db4a94;
	font-weight: 600 !important;
}

.nos-macarons .carousel-item {
	height: 300px;
	border-radius: 10px;
	overflow: hidden;
}

.day{
	width: 110px;
	text-align: left !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,+DAAqE;AACtE;;AAEA;CACC,qBAAqB;CACrB,+DAAyE;AAC1E;;AAEA;CACC,sBAAsB;CACtB,+DAAoE;AACrE;;AAEA;CACC,cAAc;CACd,2BAA2B;AAC5B;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;CACC,YAAY;CACZ,2BAA2B;AAC5B","sourcesContent":["@font-face {\n\tfont-family: 'Birds';\n\tsrc: url('../../assets/fonts/birdsofparadise.ttf') format('truetype');\n}\n\n@font-face {\n\tfont-family: 'Avenir';\n\tsrc: url('../../assets/fonts/avenirnextcondensed.ttf') format('truetype');\n}\n\n@font-face {\n\tfont-family: 'Abraham';\n\tsrc: url('../../assets/fonts/AbrahamLincoln.ttf') format('truetype');\n}\n\nb {\n\tcolor: #db4a94;\n\tfont-weight: 600 !important;\n}\n\n.nos-macarons .carousel-item {\n\theight: 300px;\n\tborder-radius: 10px;\n\toverflow: hidden;\n}\n\n.day{\n\twidth: 110px;\n\ttext-align: left !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
