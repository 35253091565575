import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import useWindowDimensions from '../../modules/hooks/useWindowDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const MenuItemContainer = styled.div`
	font-family: 'Open Sans';
	font-weight: 500;
	font-size: 0.8rem;
	margin: 0 20px;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: 1px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 5px solid transparent;

	&:hover,
	&.active {
		border-bottom: 5px solid #e4067e;
	}
`;

const MenuItem = ({ title, onClick }) => (
    <MenuItemContainer onClick={onClick}>
        { title }
    </MenuItemContainer>
)

const Navbar = ({ menuItems }) => {

    const { width } = useWindowDimensions();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    return (
        <div style={{
            width: '100%',
            height: width > 768 ? '60px' : 'auto',
            minHeight: width < 768 ? '60px' : 'auto',
            backgroundColor: '#111111',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            zIndex: 1000,
        }}>          
            { width <= 768 && (
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}>
                    { !isMenuOpen && (
                        <img 
                            src={require('../../assets/logo-mini-white.png')} 
                            alt="logo" 
                            style={{ 
                                width: 'auto', 
                                height: '35px', 
                                position: 'absolute', 
                                left: 10 
                            }} 
                        />
                    )}
                    <FontAwesomeIcon 
                        icon={faBars} 
                        style={{ 
                            cursor: 'pointer', 
                            position: 'absolute', 
                            right: '20px' 
                        }} 
                        onClick={() => setIsMenuOpen(!isMenuOpen)} 
                    />
                    { isMenuOpen && _.map(menuItems, (menuItem, index) => {
                        return (
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '60px',
                            }}>
                                <MenuItem 
                                    key={`menu-item-${index}`}
                                    title={ menuItem.title }
                                    onClick={ menuItem.onClick }
                                />
                            </div>
                        )
                    }) }
                </div>
            )}
            { width > 768 && _.map(menuItems, (menuItem, index) => {
                return (
                    <MenuItem 
                        key={`menu-item-${index}`}
                        title={ menuItem.title }
                        onClick={ menuItem.onClick }
                    />
                )
            }) }
        </div>
    )
}

Navbar.defaultProps = {
    menuItems: []
}

export default Navbar;