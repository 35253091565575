import React, { useEffect } from 'react'
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faAt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';

import useWindowDimensions from '../../modules/hooks/useWindowDimensions';

import Navbar from '../../modules/navbar';
import Header from '../../modules/header';
import Footer from '../../modules/footer';

import { macaronsList } from '../macarons/macarons';
import styled from 'styled-components';

const StepContainer = styled.div`
	width: calc(100% - 30px);
	min-height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 25px;
	border-color: #eeeeee;
	border-width: 2px;
	border-style: solid;
    margin-left: 15px;
    padding: 25px;

	h4{
		font-family: 'Open Sans';
		font-weight: 700;
		color: black;
		margin: 0;
		padding: 0;
		font-size: 1.2rem;
		text-align: center;
	}

	p{
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 300;
		color: black;
		margin: 0;
		padding: 0;
		font-size: 1rem;
		text-align: center;
		margin-top: 0;
        margin-bottom: 25px;
	}
`;

const Macarons = () => {

	const { width } = useWindowDimensions();

	const [stepIndex, setStepIndex] = React.useState(0);	
	const [stepResponses, setStepResponses] = React.useState({
		'step-1': null,
		'step-2': null,
		'step-3': null,
		'step-4': null
	});
	const [responseList, setResponseList] = React.useState([]);

	const menuItems = [
		{ title: 'Accueil', onClick: () => window.location.href = '/' },
		{ title: 'Nos macarons', onClick: () => window.location.href = '/macarons/all' },
		{ title: 'Nos cartes', onClick: () => window.open('https://www.canva.com/design/DAFqR7GcTlk/vsYS9DyJhkRyS-wPJWibfw/edit') },
		{ title: 'Evenementiel', onClick: () => window.location.href = '/' },
		{ title: 'Gallerie', onClick: () => window.location.href = '/gallerie' },
        { title: 'Macaron IA', onClick: () => window.location.href = '/macaron-ia' },
		{ title: 'Contact', onClick: () => window.location.href = '/' },
	]
	
	useEffect(() => {
		filterMacaronsList();
	}, [stepResponses]);

	const renderResponsesList = (responses) => {
		return _.map(responses, (response, index) => {
			return (
				<button
					key={`response-${stepIndex}-${index}`}
					style={{
						backgroundColor: '#db4a94',
						color: 'white',
						border: 'none',
						fontFamily: 'Open Sans',
						fontWeight: 700,
						fontSize: '0.9rem',
						padding: '10px 20px',
						marginRight: '10px',
						cursor: 'pointer',
						borderRadius: '25px'
					}}
					onClick={() => {
						// Save response
						setStepResponses({
							...stepResponses,
							[`step-${stepIndex}`]: response
						});
					}
				}>
					{ response }
				</button>
			);
		});
	}

	const filterMacaronsList = () => {
		
        console.log('filterMacaronsList', stepResponses, stepIndex);

        if( stepIndex === 0 ) return;

		let list = macaronsList;
		if( stepResponses['step-1'] != null ){
			list = _.filter(list, (macaron) => {
				return stepResponses['step-1'] === 'Sucrés' ? macaron.type === 1 : macaron.type === 2;
			});
		}
		if( stepResponses['step-2'] != null ){
			list = _.filter(list, (macaron) => {
				return stepResponses['step-2'] === 'Fruités' ? macaron.saveur === 1 : macaron.saveur === 2;
			});
		}
		if( stepResponses['step-3'] != null ){
			list = _.filter(list, (macaron) => {
				return stepResponses['step-3'] === 'Simple' ? macaron.complexe === 0 : macaron.complexe === 1;
			});
		}
	
        if( stepResponses['step-5'] === 'Oui' ){
            list = _.filter(macaronsList, (macaron) => {
                return macaron.alcool === 1;
            });
            if( stepResponses['step-3'] != null ){
                list = _.filter(list, (macaron) => {
                    return stepResponses['step-3'] === 'Simple' ? macaron.complexe === 0 : macaron.complexe === 1;
                });
            }
        }
		
		if( list.length > 0 && stepIndex < 6 ){
            setResponseList(list);
			setStepIndex(stepIndex + 1);		
		}
		else {
            if( list.length > 3 ){
                setResponseList(_.sampleSize(list, 3))
            }else{
                setResponseList(list);
            }
			setStepIndex(6);
		}
	}
					
	const renderCurrentStep = () => {	
		if( stepIndex === 0 ) {
			return (
				<StepContainer>
					<p>
						Le Monde du Macaron vous propose de découvrir votre macaron idéal grâce à l'Intelligence Artificielle.
						<br/>
						Répondez à quelques questions pour découvrir le macaron qui vous correspond le mieux.
					</p>
					<button 
						style={{
							background: '#d1d1d1',
							color: 'white',
							border: 'none',
							fontFamily: 'Open Sans',
							fontWeight: 700,
							fontSize: '0.9rem',
							padding: '10px 20px',
							marginTop: '15px',
							cursor: 'pointer',
							borderRadius: '25px'
						}}
						onClick={() => setStepIndex(1) }
					>
						Commencer le test
					</button>
				</StepContainer>
			)
		}
		if( stepIndex === 1 ) {
			return (
				<StepContainer>
					<p>Préférez-vous les saveurs sucrées ou salées ?</p>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}>
						{ renderResponsesList(["Sucrés", "Salés"])}
					</div>
				</StepContainer>
			)
		}
		if( stepIndex === 2 ) {
			return (
				<StepContainer>
					<p>Avez-vous une préférence pour les parfums fruités ou ceux plus gourmands ?</p>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}>
						{ renderResponsesList(["Fruités", "Gourmands"])}
					</div>
				</StepContainer>
			)
		}
		if( stepIndex === 3 ) {
			return (
				<StepContainer>
					<p>Préférez-vous des macarons simples ou des mélanges de saveurs ?</p>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}>
						{ renderResponsesList(["Simple", "Complexes"])}
					</div>
				</StepContainer>
			)
		}
		if( stepIndex === 4 ) {
			return (
				<StepContainer>
					<p>Souhaitez-vous expérimenter de nouvelles saveurs ou préférez-vous rester classique ?</p>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}>
						{ renderResponsesList(["Aventure", "Classique"])}
					</div>
				</StepContainer>
			)
		}
        if( stepIndex === 5 ) {
			return (
				<StepContainer>
					<p>Aimez-vous les macarons au goûts alcoolisés ?</p>
					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}>
						{ renderResponsesList(["Oui", "Non"])}
					</div>
				</StepContainer>
			)
		}
		if( stepIndex === 6 ) {
			return (
				<StepContainer>
					<p>Votre macaron idéal est ...</p>
					<div style={{
						display: 'flex',
						flexDirection: width > 768 ? 'row' : 'column',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}>
                        { responseList.length === 0 && (
                            <h4 style={{
                                fontFamily: 'Open Sans',
                                fontWeight: 400,
                                color: 'black',
                                margin: 0, 
                                fontSize: '1rem',
                                marginTop: '5px',
                                marginBottom: '25px',
                            }}>
                                Malheureusement, aucun de nos macaron ne correspond à vos réponses
                            </h4>
                        )}
						{ !!responseList.length && _.map(_.sampleSize(responseList, 1), (macaron, index) => {
							return (
								<div key={`macaron-${index}`} style={{
									display: 'flex',
									width: '170px',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                    marginBottom: width < 768 ? '25px' : 0
								}}>
									<img 
										src={ macaron.image } 
										alt={ macaron.title } 
										style={{
											width: '170px',
											height: '170px',
											borderRadius: '100px'
										}}
									/>
									<h2 style={{
										fontFamily: 'Open Sans',
										fontSize: '1rem',
										margin: 0,
										padding: 0,
										color: 'black',
										marginTop: '10px',
										textAlign: 'center'
									}}>
										{ macaron.title }
									</h2>
								</div>
							)
						}) }
					</div>
					<button 
						style={{
							background: '#d1d1d1',
							color: 'white',
							border: 'none',
							fontFamily: 'Open Sans',
							fontWeight: 700,
							fontSize: '0.9rem',
							padding: '10px 20px',
							marginTop: '25px',
							cursor: 'pointer',
							borderRadius: '25px'
						}}
						onClick={() => {
							setStepResponses({
								'step-1': null,
								'step-2': null,
								'step-3': null,
								'step-4': null
							});
							setStepIndex(1);
						}}
					>
						Recommencer le test
					</button>
				</StepContainer>
			)
		}
	}

	return (
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh'
		}}>

			<Navbar menuItems={menuItems} />

			<Header />

			{/* Content */}
			<section className='nos-macarons' style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				width: '100%',
				borderTop: '3px solid #db4a94',
			}}>
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: '60px',
					paddingBottom: '60px',
				}}>

					<img 
						src={require('../../assets/title-macarons.png')} 
						alt="title" 
						width={'75px'}
					/>

					<h1 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '2.2rem',
						margin: '0 auto',
						padding: 0,
						color: 'black',
						marginTop: '15px',
						marginBottom: 0,
                        textAlign: 'center'
					}}>
						Quel macaron êtes-vous ?
					</h1>
                    <h3 style={{
                        fontFamily: 'Open Sans',
                        fontWeight: 400,
                        fontSize: '1rem',
                        textTransform: 'uppercase',
                        letterSpacing: '1px',
                        margin: 0,
                        padding: 0,
                        color: '#FF80AB',
                        marginTop: '5px',
                        marginBottom: '25px',
                        textAlign: 'center'
                    }}>
                        L'Intelligence Artificielle vous aide à trouver votre macaron idéal ✨
                    </h3>

					
                    <img 
						alt="separator-france"
						src={require('../../assets/separator-france.png')} 
						width='200px' 
						style={{
							boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px 5px',
						}}
					/>

				
					{/* Whats my macaron */}
					<div style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '60px'
					}}>
				
						<div style={{
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							marginTop: '25px',
						}}>
							{ renderCurrentStep() }
						</div>

					</div>

					<button 
						style={{
							background: '#e4067e',
							color: 'white',
							border: 'none',
							fontFamily: 'Open Sans',
							fontWeight: 700,
							fontSize: '0.9rem',
							padding: '10px 20px',
							marginTop: '60px',
							cursor: 'pointer',
							borderRadius: '25px'
						}}
						onClick={() => window.location.href = '/' }
					>
						Retour à l'accueil
					</button>

                </div>
		    </section>

			<section style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				backgroundColor: '#303031',
				position: 'relative'
			}}>
				<div style={{
					width: '100%',
					opacity: 0,
					top: '0',
					position: 'absolute'
				}} />
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: '60px',
					paddingBottom: '60px',
				}}>

					<h1 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '2.2rem',
						margin: '0 auto',
						padding: 0,
						color: 'white',
					}}>
						Contact
					</h1>


					<div style={{
						marginTop: '25px'
					}}>
						<p style={{
							margin: 0,
							padding: 0,
							fontWeight: 300,
							textAlign: 'center',
							fontFamily: 'Open Sans',
							color: 'white',
						}}>
							Le Monde du Macaron<br/>
							885 Rue nationale<br/>
							69400 Villefranche-sur-Saône
						</p>
					</div>

					<div style={{
						marginTop: '25px'
					}}>
						<p style={{
							margin: 0,
							padding: 0,
							fontWeight: 300,
							textAlign: 'center',
							fontFamily: 'Open Sans',
							color: 'white',
						}}>
							<FontAwesomeIcon icon={faPhone} style={{ marginRight: '10px' }} />
							<span>+33 (0)4 74 67 68 35</span>
							<br/>
							<FontAwesomeIcon icon={faAt} style={{ marginRight: '10px' }} />
							<span>
								<a href="mailto:lmdm.villefranche@gmail.com" style={{
									color: 'white',
									textDecoration: 'none'
								}}>
									lmdm.villefranche@gmail.com
								</a>
							</span>
						</p>
					</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						marginTop: '50px'
					}}>

						{ width > 768 && (
							<img 
								src={require('../../assets/logo-negatif.png')} 
								width='150px'
								alt='logo-negatif'
							/>
						)}

						<div style={{
							borderLeft: width > 768 ? '3px solid white' : 'none',
							textAlign: width > 768 ? 'left' : 'center',
							paddingLeft: width > 768 ? '45px' : '15px',
							paddingRight: width > 768 ? 0 : '15px',
							marginLeft: width > 768 ? '45px' : 0,
						}}>
							<article style={{
								fontFamily: 'Open Sans',
								fontWeight: 300,
								color: 'white',
								margin: 0,
								padding: 0,
								fontSize: '1rem'
							}}>
								<p style={{
									margin: 0
								}}>
									<span style={{
										fontWeight: 700,
										color: 'white'
									}}>
										Horaires d'ouvertures
									</span>
									<br />
									<div style={{
										display: 'flex',
										flexDirection: 'column',
										marginTop: '5px'
									}}>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Lundi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Mardi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Mercredi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Jeudi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Vendredi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Samedi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
									</div>
								</p>
							</article>
						</div>

					</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						alignItems: 'center',
						width: '100%',
						maxWidth: '300px',
						marginTop: '35px'
					}}>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.facebook.com/profile.php?id=100083022027611' }
						>
							<FontAwesomeIcon icon={faFacebookF} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.instagram.com/lemondedumacaron.69400/' }
						>
							<FontAwesomeIcon icon={faInstagram} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.tiktok.com/@lemondedumacaron.69400' }
						>
							<FontAwesomeIcon icon={faTiktok} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
					</div>

				</div>
			</section>

			<Footer />
        
        </div>
	);
}

export default Macarons;
