export const macaronsList = [
    {
        id: 1,
        title:'Ananas Vanille',
        image: require('../../assets/macarons/ananas_vanille.webp'),
        type: 1, // Sucré
        saveur: 1, // Fruité
        complexe: 0, // Simple
        traditionnel: 0, // Exotique ou Tradi 
        alcool: 0
    },
    {
        id: 2,
        title:'Barba à Papa',
        image: require('../../assets/macarons/barbe_a_papa.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 3,
        title: 'Bergamotte',
        image: require('../../assets/macarons/bergamotte.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 4,
        title: 'Biscuit Petit Beurre',
        image: require('../../assets/macarons/biscuit_petit_beurre.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 5,
        title: 'Bounty',
        image: require('../../assets/macarons/bounty.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 6,
        title: 'Cacahuète enrobée M&Ms',
        image: require('../../assets/macarons/cacahuete_enrobe_mms.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 7,
        title: 'Café',
        image: require('../../assets/macarons/cafe.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 8,
        title: 'Calisson',
        image: require('../../assets/macarons/calisson.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 9,
        title: 'Caramel Beurre Salé',
        image: require('../../assets/macarons/caramel_beurre_sale.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 10,
        title: 'Cassis',
        image: require('../../assets/macarons/cassis.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 11,
        title: 'Cerise / Pistache',
        image: require('../../assets/macarons/cerise-pistache.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 12,
        title: 'Cerise',
        image: require('../../assets/macarons/cerise.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 13,
        title: 'Champagne',
        image: require('../../assets/macarons/champagne.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 1
    },
    {
        id: 14,
        title: 'Chocolat Blanc',
        image: require('../../assets/macarons/chocolat_blanc.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 15,
        title: 'Chocolat / Framboise',
        image: require('../../assets/macarons/chocolat_framboise.webp'),
        type: 1,
        saveur: 2,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 16,
        title: 'Chocolat / Passion',
        image: require('../../assets/macarons/chocolat-passion.webp'),
        type: 1,
        saveur: 2,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 17,
        title: 'Chocolat',
        image: require('../../assets/macarons/chocolat.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 18,
        title: 'Citron Vert / Cactus',
        image: require('../../assets/macarons/citron_vert_cactus.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 19,
        title: 'Citron',
        image: require('../../assets/macarons/citron.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 20,
        title: 'Cookie',
        image: require('../../assets/macarons/cookie.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 21,
        title: 'Coquelicot',
        image: require('../../assets/macarons/coquelicot.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 22,
        title: 'Crème Brûlée',
        image: require('../../assets/macarons/creme-brulee.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 23,
        title: 'Crumble Pomme',
        image: require('../../assets/macarons/crumble-pomme.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 24,
        title: 'Fève Tonka',
        image: require('../../assets/macarons/feve-tonka.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 25,
        title: 'Figue',
        image: require('../../assets/macarons/figue.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 26,
        title: 'Fleur d’Oranger',
        image: require('../../assets/macarons/fleur-oranger.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 27,
        title: 'Fraise Tagada',
        image: require('../../assets/macarons/fraise_bonbon.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 28,
        title: 'Fraise',
        image: require('../../assets/macarons/fraise.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 29,
        title: 'Framboise / Litchi',
        image: require('../../assets/macarons/framboise-litchi.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 30,
        title: 'Framboise / Myrtille',
        image: require('../../assets/macarons/framboise-myrtilles.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 31,
        title: 'Framboise',
        image: require('../../assets/macarons/framboise.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 32,
        title: 'Gianduja',
        image: require('../../assets/macarons/gianduja.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 33,
        title: 'Grand Marnier',
        image: require('../../assets/macarons/grand-marnier.webp'),
        type: 1,
        saveur: 0,
        complexe: 0,
        traditionnel: 1,
        alcool: 1
    },
    {
        id: 34,
        title: 'Guimauve',
        image: require('../../assets/macarons/guimauve.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 35,
        title: 'Kiwi Banane',
        image: require('../../assets/macarons/kiwi_banane.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 36,
        title: 'Mandarine / Yuzu',
        image: require('../../assets/macarons/mandarine-yuzu.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 37,
        title: 'Mangue / Passion',
        image: require('../../assets/macarons/mangue_passion.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 38,
        title: 'Marron Glacé',
        image: require('../../assets/macarons/marron_glace.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 39,
        title: 'Melon',
        image: require('../../assets/macarons/melon.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 40,
        title: 'Menthe Glaciale',
        image: require('../../assets/macarons/menthe-glaciale.webp'),
        type: 1,
        saveur: 0,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 41,
        title: 'Mirabelle / Thym',
        image: require('../../assets/macarons/mirabelle_thym.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 42,
        title: 'Mojito',
        image: require('../../assets/macarons/mojito.webp'),
        type: 1,
        saveur: 0,
        complexe: 1,
        traditionnel: 0,
        alcool: 1
    },
    {
        id: 43,
        title: 'Myrtille',
        image: require('../../assets/macarons/myrtille.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 44,
        title: 'Noix de Coco',
        image: require('../../assets/macarons/noix_de_coco.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 45,
        title: 'Nougat',
        image: require('../../assets/macarons/nougat.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 46,
        title: 'Nutella',
        image: require('../../assets/macarons/nutella.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 47,
        title: 'Oreo',
        image: require('../../assets/macarons/oreo.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 48,
        title: 'Pain d’Épices',
        image: require('../../assets/macarons/pain-epices.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 49,
        title: 'Pêche de Vigne',
        image: require('../../assets/macarons/peche_de_vigne.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 50,
        title: 'Pistache',
        image: require('../../assets/macarons/pistache.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 51,
        title: 'Poire / Thé Vert',
        image: require('../../assets/macarons/poire_the_vert.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 52,
        title: 'Pomme d’Amour',
        image: require('../../assets/macarons/pomme_damour.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 53,
        title: 'Praline Rose / Grenadine',
        image: require('../../assets/macarons/praline_rose_grenadine.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 54,
        title: 'Praline',
        image: require('../../assets/macarons/praline.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 55,
        title: 'Rhum / Raisin',
        image: require('../../assets/macarons/rhum_raisin.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 1,
        alcool: 1
    },
    {
        id: 56,
        title: 'Speculoos',
        image: require('../../assets/macarons/speculoos.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 57,
        title: 'Tarte aux Fraises',
        image: require('../../assets/macarons/tarte_aux_fraises.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 58,
        title: 'Tarte Citron',
        image: require('../../assets/macarons/tarte_citron.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 59,
        title: 'Tarte Tatin',
        image: require('../../assets/macarons/tarte_tatin.webp'),
        type: 1,
        saveur: 2,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 60,
        title: 'Tiramisu',
        image: require('../../assets/macarons/tiramisu.webp'),
        type: 1,
        saveur: 2,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 61,
        title: 'Tutti Fruity',
        image: require('../../assets/macarons/tutti_fruity.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 62,
        title: 'Vanille / Fraise',
        image: require('../../assets/macarons/vanille_fraise.webp'),
        type: 1,
        saveur: 1,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 63,
        title: 'Vanille / Noix de Pécan',
        image: require('../../assets/macarons/vanille_noix_de_pecan.webp'),
        type: 1,
        saveur: 2,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 64,
        title: 'Vanille',
        image: require('../../assets/macarons/vanille.webp'),
        type: 1,
        saveur: 2,
        complexe: 0,
        traditionnel: 1
    },
    {
        id: 65,
        title: 'Violette',
        image: require('../../assets/macarons/violette.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 66,
        title: 'Yuzu',
        image: require('../../assets/macarons/yuzu.webp'),
        type: 1,
        saveur: 1,
        complexe: 0,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 67,
        title: 'Abricot / Fleur de Sel',
        image: require('../../assets/macarons/abricot_vanille_fleur_de_sel.webp'),
        type: 2,
        saveur: 1,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 68,
        title: 'Boursin',
        image: require('../../assets/macarons/boursin.webp'),
        type: 2,
        saveur: 0,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 69,
        title: 'Chèvre / Basilic',
        image: require('../../assets/macarons/chevre_basilic.webp'),
        type: 2,
        saveur: 0,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 70,
        title: 'Chèvre / Thym',
        image: require('../../assets/macarons/chevre-thym.webp'),
        type: 2,
        saveur: 0,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 71,
        title: 'Chorizo',
        image: require('../../assets/macarons/chorizo.webp'),
        type: 2,
        saveur: 0,
        complexe: 0,
        traditionnel: 1
    },
    {
        id: 72,
        title: 'Figue / Oignon',
        image: require('../../assets/macarons/figue-oignon.webp'),
        type: 2,
        saveur: 0,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 73,
        title: 'Foie Gras / Piment',
        image: require('../../assets/macarons/foie-piment.webp'),
        type: 2,
        saveur: 0,
        complexe: 1,
        traditionnel: 0,
        alcool: 0
    },
    {
        id: 74,
        title: 'Roquefort',
        image: require('../../assets/macarons/roquefort.webp'),
        type: 2,
        saveur: 0,
        complexe: 0,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 75,
        title: 'Roquefort / Noix',
        image: require('../../assets/macarons/roquefort_noix.webp'),
        type: 2,
        saveur: 0,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 76,
        title: 'Saumon / Aneth',
        image: require('../../assets/macarons/saumon_aneth.webp'),
        type: 2,
        saveur: 0,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    },
    {
        id: 77,
        title: 'Tomate / Basilic',
        image: require('../../assets/macarons/tomate-basilic.webp'),
        type: 2,
        saveur: 0,
        complexe: 1,
        traditionnel: 1,
        alcool: 0
    }
];