import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faAt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

import useWindowDimensions from '../../modules/hooks/useWindowDimensions';

import Navbar from '../../modules/navbar';
import Header from '../../modules/header';
import Footer from '../../modules/footer';

const Gallerie = () => {

	const { width } = useWindowDimensions();

	const menuItems = [
		{ title: 'Accueil', onClick: () => window.location.href = '/' },
		{ title: 'Nos macarons', onClick: () => window.location.href = '/macarons/all' },
		{ title: 'Fabrication', onClick: () => window.location.href = '/fabrication' },
		{ title: 'Nos cartes', onClick: () => window.open('https://www.canva.com/design/DAFqR7GcTlk/vsYS9DyJhkRyS-wPJWibfw/edit') },
		{ title: 'Evenementiel', onClick: () => window.location.href = '/' },
		{ title: 'Gallerie', onClick: () => window.location.href = '/gallerie' },
		{ title: 'Macaron IA', onClick: () => window.location.href = '/macaron-ia' },
		{ title: 'Contact', onClick: () => window.location.href = '/' },
	]

	return (
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh'
		}}>

			<Navbar menuItems={menuItems} />

			<Header />

			{/* Content */}
			<section className='nos-macarons' style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				width: '100%',
				borderTop: '3px solid #db4a94',
			}}>
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: '60px',
					paddingBottom: '60px',
				}}>

					<img 
						src={require('../../assets/title-macarons.png')} 
						alt="title" 
						width={'75px'}
					/>

					<h1 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '2.2rem',
						margin: '0 auto',
						padding: 0,
						color: 'black',
						marginTop: '15px',
						marginBottom: '15px',
					}}>
						Gallerie photos
					</h1>

					<img 
						alt="separator-france"
						src={require('../../assets/separator-france.png')} 
						width='200px' 
						style={{
							boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px 5px',
						}}
					/>

					

					<button 
						style={{
							background: '#e4067e',
							color: 'white',
							border: 'none',
							fontFamily: 'Open Sans',
							fontWeight: 700,
							fontSize: '0.9rem',
							padding: '10px 20px',
							marginTop: '60px',
							cursor: 'pointer',
							borderRadius: '25px'
						}}
						onClick={() => window.location.href = '/' }
					>
						Retour à l'accueil
					</button>

                </div>
		    </section>

			<section style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				backgroundColor: '#303031',
				position: 'relative'
			}}>
				<div style={{
					width: '100%',
					opacity: 0,
					top: '0',
					position: 'absolute'
				}} />
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: '60px',
					paddingBottom: '60px',
				}}>

					<h1 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '2.2rem',
						margin: '0 auto',
						padding: 0,
						color: 'white',
					}}>
						Contact
					</h1>


					<div style={{
						marginTop: '25px'
					}}>
						<p style={{
							margin: 0,
							padding: 0,
							fontWeight: 300,
							textAlign: 'center',
							fontFamily: 'Open Sans',
							color: 'white',
						}}>
							Le Monde du Macaron<br/>
							885 Rue nationale<br/>
							69400 Villefranche-sur-Saône
						</p>
					</div>

					<div style={{
						marginTop: '25px'
					}}>
						<p style={{
							margin: 0,
							padding: 0,
							fontWeight: 300,
							textAlign: 'center',
							fontFamily: 'Open Sans',
							color: 'white',
						}}>
							<FontAwesomeIcon icon={faPhone} style={{ marginRight: '10px' }} />
							<span>+33 (0)4 74 67 68 35</span>
							<br/>
							<FontAwesomeIcon icon={faAt} style={{ marginRight: '10px' }} />
							<span>
								<a href="mailto:lmdm.villefranche@gmail.com" style={{
									color: 'white',
									textDecoration: 'none'
								}}>
									lmdm.villefranche@gmail.com
								</a>
							</span>
						</p>
					</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						marginTop: '50px'
					}}>

						{ width > 768 && (
							<img 
								src={require('../../assets/logo-negatif.png')} 
								width='150px'
								alt='logo-negatif'
							/>
						)}

						<div style={{
							borderLeft: width > 768 ? '3px solid white' : 'none',
							textAlign: width > 768 ? 'left' : 'center',
							paddingLeft: width > 768 ? '45px' : '15px',
							paddingRight: width > 768 ? 0 : '15px',
							marginLeft: width > 768 ? '45px' : 0,
						}}>
							<article style={{
								fontFamily: 'Open Sans',
								fontWeight: 300,
								color: 'white',
								margin: 0,
								padding: 0,
								fontSize: '1rem'
							}}>
								<p style={{
									margin: 0
								}}>
									<span style={{
										fontWeight: 700,
										color: 'white'
									}}>
										Horaires d'ouvertures
									</span>
									<br />
									<div style={{
										display: 'flex',
										flexDirection: 'column',
										marginTop: '5px'
									}}>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Lundi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Mardi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Mercredi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Jeudi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Vendredi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Samedi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
									</div>
								</p>
							</article>
						</div>

					</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						alignItems: 'center',
						width: '100%',
						maxWidth: '300px',
						marginTop: '35px'
					}}>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.facebook.com/profile.php?id=100083022027611' }
						>
							<FontAwesomeIcon icon={faFacebookF} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.instagram.com/lemondedumacaron.69400/' }
						>
							<FontAwesomeIcon icon={faInstagram} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.tiktok.com/@lemondedumacaron.69400' }
						>
							<FontAwesomeIcon icon={faTiktok} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
					</div>

				</div>
			</section>

            <Footer />
        
        </div>
	);
}

export default Gallerie;
