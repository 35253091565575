import React, { useEffect } from 'react'
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faAt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';

import useWindowDimensions from '../../modules/hooks/useWindowDimensions';

import Navbar from '../../modules/navbar';
import Header from '../../modules/header';
import Footer from '../../modules/footer';

import { macaronsList } from './macarons';
import styled from 'styled-components';

const StepContainer = styled.div`
	width: 100%;
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 25px;
	border-color: #eeeeee;
	border-width: 2px;
	border-style: solid;

	h4{
		font-family: 'Open Sans';
		font-weight: 700;
		color: black;
		margin: 0;
		padding: 0;
		font-size: 1.2rem;
		text-align: center;
	}

	p{
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 300;
		color: black;
		margin: 0;
		padding: 0;
		font-size: 1rem;
		text-align: center;
		margin-top: 15px;
	}
`;

const Macarons = () => {

	const { width } = useWindowDimensions();

	const menuItems = [
		{ title: 'Accueil', onClick: () => window.location.href = '/' },
		{ title: 'Nos macarons', onClick: () => window.location.href = '/macarons/all' },
		{ title: 'Nos cartes', onClick: () => window.open('https://www.canva.com/design/DAFqR7GcTlk/vsYS9DyJhkRyS-wPJWibfw/edit') },
		{ title: 'Evenementiel', onClick: () => window.location.href = '/' },
		{ title: 'Gallerie', onClick: () => window.location.href = '/gallerie' },
		{ title: 'Macaron IA', onClick: () => window.location.href = '/macaron-ia' },
		{ title: 'Contact', onClick: () => window.location.href = '/' },
	]

	const [currentFilter, setCurrentFilter] = React.useState('all');
	const list = _.filter(macaronsList, (macaron) => {
		if (currentFilter === 'all') {
			return true;
		} else {
			if( currentFilter === 'sucres' ) {
				return macaron.type === 'sucre';
			}
			if( currentFilter === 'sales' ) {
				return macaron.type === 'sale';
			}
		}
	});

	return (
		<div style={{
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh'
		}}>

			<Navbar menuItems={menuItems} />

			<Header />

			{/* Content */}
			<section className='nos-macarons' style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				width: '100%',
				borderTop: '3px solid #db4a94',
			}}>
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: '60px',
					paddingBottom: '60px',
				}}>

					<img 
						src={require('../../assets/title-macarons.png')} 
						alt="title" 
						width={'75px'}
					/>

					<h1 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '2.2rem',
						margin: '0 auto',
						padding: 0,
						color: 'black',
						marginTop: '15px',
						marginBottom: '15px',
					}}>
						Nos macarons
					</h1>

					<img 
						alt="separator-france"
						src={require('../../assets/separator-france.png')} 
						width='200px' 
						style={{
							boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px 5px',
						}}
					/>

					{/* Filtres */}
					<div style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: width > 768 ? 'flex-start' : 'center',
						alignItems: 'flex-start',
						marginTop: '60px',
						paddingLeft: '15px',
						paddingRight: '15px'
					}}>
						<button 
							onClick={() => setCurrentFilter('all')}
							style={{
								backgroundColor: currentFilter === 'all' ? '#db4a94' : 'white',
								color: currentFilter === 'all' ? 'white' : '#dedede',
								fontFamily: 'Open Sans',
								fontSize: '1rem',
								fontWeight: 600,
								padding: '10px 15px',
								borderRadius: '5px',
								marginRight: '5px',
								border: currentFilter === 'all' ? '2px solid #db4a94' : '2px solid #dedede'
							}}
						>
							Tous
						</button>
						<button 
							onClick={() => setCurrentFilter('sucres')}
							style={{
								backgroundColor: currentFilter === 'sucres' ? '#db4a94' : 'white',
								color: currentFilter === 'sucres' ? 'white' : '#dedede',
								fontFamily: 'Open Sans',
								fontSize: '1rem',
								fontWeight: 600,
								padding: '10px 15px',
								borderRadius: '5px',
								marginRight: '5px',
								border: currentFilter === 'sucres' ? '2px solid #db4a94' : '2px solid #dedede'
							}}
						>
							Sucrés
						</button>
						<button 
							onClick={() => setCurrentFilter('sales')}
							style={{
								backgroundColor: currentFilter === 'sales' ? '#db4a94' : 'white',
								color: currentFilter === 'sales' ? 'white' : '#dedede',
								fontFamily: 'Open Sans',
								fontSize: '1rem',
								fontWeight: 600,
								padding: '10px 15px',
								borderRadius: '5px',
								marginRight: '5px',
								border: currentFilter === 'sales' ? '2px solid #db4a94' : '2px solid #dedede'
							}}
						>
							Salés
						</button>
					</div>

                    {/* Liste des macarons */}
					<div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
						flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        marginTop: '35px'
                    }}>
						{ _.map(list, (macaron, index) => {
							return (
								<div key={`macaron-${index}`} style={{
									display: 'flex',
									width: '170px',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									margin: '15px'
								}}>
									<img 
										src={ macaron.image } 
										alt={ macaron.title } 
										style={{
											width: '100%',
											height: '170px',
											borderRadius: '100px'
										}}
									/>
									<h2 style={{
										fontFamily: 'Open Sans',
										fontSize: '1rem',
										margin: 0,
										padding: 0,
										color: 'black',
										marginTop: '10px',
										textAlign: 'center'
									}}>
										{ macaron.title }
									</h2>
								</div>
							)
						}) }
                    </div>	

					<button 
						style={{
							background: '#e4067e',
							color: 'white',
							border: 'none',
							fontFamily: 'Open Sans',
							fontWeight: 700,
							fontSize: '0.9rem',
							padding: '10px 20px',
							marginTop: '60px',
							cursor: 'pointer',
							borderRadius: '25px'
						}}
						onClick={() => window.location.href = '/' }
					>
						Retour à l'accueil
					</button>

                </div>
		    </section>

			<section style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				backgroundColor: '#303031',
				position: 'relative'
			}}>
				<div style={{
					width: '100%',
					opacity: 0,
					top: '0',
					position: 'absolute'
				}} />
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: '60px',
					paddingBottom: '60px',
				}}>

					<h1 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '2.2rem',
						margin: '0 auto',
						padding: 0,
						color: 'white',
					}}>
						Contact
					</h1>


					<div style={{
						marginTop: '25px'
					}}>
						<p style={{
							margin: 0,
							padding: 0,
							fontWeight: 300,
							textAlign: 'center',
							fontFamily: 'Open Sans',
							color: 'white',
						}}>
							Le Monde du Macaron<br/>
							885 Rue nationale<br/>
							69400 Villefranche-sur-Saône
						</p>
					</div>

					<div style={{
						marginTop: '25px'
					}}>
						<p style={{
							margin: 0,
							padding: 0,
							fontWeight: 300,
							textAlign: 'center',
							fontFamily: 'Open Sans',
							color: 'white',
						}}>
							<FontAwesomeIcon icon={faPhone} style={{ marginRight: '10px' }} />
							<span>+33 (0)4 74 67 68 35</span>
							<br/>
							<FontAwesomeIcon icon={faAt} style={{ marginRight: '10px' }} />
							<span>
								<a href="mailto:lmdm.villefranche@gmail.com" style={{
									color: 'white',
									textDecoration: 'none'
								}}>
									lmdm.villefranche@gmail.com
								</a>
							</span>
						</p>
					</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						marginTop: '50px'
					}}>

						{ width > 768 && (
							<img 
								src={require('../../assets/logo-negatif.png')} 
								width='150px'
								alt='logo-negatif'
							/>
						)}

						<div style={{
							borderLeft: width > 768 ? '3px solid white' : 'none',
							textAlign: width > 768 ? 'left' : 'center',
							paddingLeft: width > 768 ? '45px' : '15px',
							paddingRight: width > 768 ? 0 : '15px',
							marginLeft: width > 768 ? '45px' : 0,
						}}>
							<article style={{
								fontFamily: 'Open Sans',
								fontWeight: 300,
								color: 'white',
								margin: 0,
								padding: 0,
								fontSize: '1rem'
							}}>
								<p style={{
									margin: 0
								}}>
									<span style={{
										fontWeight: 700,
										color: 'white'
									}}>
										Horaires d'ouvertures
									</span>
									<br />
									<div style={{
										display: 'flex',
										flexDirection: 'column',
										marginTop: '5px'
									}}>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Lundi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Mardi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Mercredi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Jeudi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Vendredi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Samedi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
									</div>
								</p>
							</article>
						</div>

					</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						alignItems: 'center',
						width: '100%',
						maxWidth: '300px',
						marginTop: '35px'
					}}>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.facebook.com/profile.php?id=100083022027611' }
						>
							<FontAwesomeIcon icon={faFacebookF} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.instagram.com/lemondedumacaron.69400/' }
						>
							<FontAwesomeIcon icon={faInstagram} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.tiktok.com/@lemondedumacaron.69400' }
						>
							<FontAwesomeIcon icon={faTiktok} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
					</div>

				</div>
			</section>

			<Footer />
        
        </div>
	);
}

export default Macarons;
