import React from 'react';

const Footer = () => {
    return (
        <section style={{
            display: 'flex',
            width: '100%',
            height: '50px',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: '#111111',
            color: 'white'
        }}>
            <div style={{
                width: '100%',
                maxWidth: '1200px',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                paddingTop: '20px',
                paddingBottom: '20px',
            }}>
                <p style={{
                    fontFamily: 'Open Sans',
                    fontWeight: 300,
                    color: 'white',
                    margin: 0,
                    padding: 0,
                    fontSize: '0.8rem',
                    textAlign: 'center'
                }}>
                    Le Monde du Macaron - 2024 - Tous droits réservés
                </p>
            </div>
        </section>
    )
}

export default Footer;