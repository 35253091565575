import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useTransition, animated, useSpringRef } from '@react-spring/web';
import _ from 'lodash';
import useWindowDimensions from '../hooks/useWindowDimensions';
import styled from 'styled-components';

const HeaderTitleLocationPart = styled.h1`
    width: 50%;
    font-family: 'Birds';
    font-weight: 300;
    font-size: 2rem;
    line-height: 45px;
    margin: 0 auto;
    padding: 0;
    color: black;
    margin-top: 0;
    margin-right: 10px;
    margin-left: 10px;
    padding-top: 5px;

    @media(max-width: 1080px) {
        font-size: 1.2rem;
    }
`;

const Header = () => {

    const { width } = useWindowDimensions();
    const [index, setIndex] = useState(0);
	const transRef = useSpringRef();

    const titleStyle = {
        fontFamily: 'Open Sans',
        fontWeight: 900,
        fontSize: width > 1080 ? '1.3rem' : '1rem',
        padding: '0px 10px',
        color: 'white',
        backgroundColor: '#db4a94',
        lineHeight: '45px'
    };

    const pages = [
		({ style }) => <animated.div style={{ ...titleStyle, ...style }}>Salon de thé</animated.div>,
		({ style }) => <animated.div style={{ ...titleStyle, ...style }}>Gauffres & Crêpes</animated.div>,
		({ style }) => <animated.div style={{ ...titleStyle, ...style }}>Macarons</animated.div>,
		({ style }) => <animated.div style={{ ...titleStyle, ...style }}>Sunny Pockets</animated.div>,
		({ style }) => <animated.div style={{ ...titleStyle, ...style }}>Pâtisseries</animated.div>,
		({ style }) => <animated.div style={{ ...titleStyle, ...style }}>Glaces</animated.div>,
	];
    
    // entering from bottom, disappearing to top
	const transitions = useTransition(index, {
		ref: transRef,
		keys: null,
		from: { opacity: 0, transform: 'translateY(100%)' },
		enter: { opacity: 1, transform: 'translateY(0)' },
		leave: { opacity: 0, transform: 'translateY(-100%)' },
	});

    useEffect(() => {
		transRef.start()
	}, [index, transRef]);

    if( width < 1080 ){
        return (
            <div style={{
                width: '100%',
                height: '750px',
                position: 'relative',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 20px 5px',
            }}>
                <Carousel
                    fade 
                    indicators={false}
                    controls={false}
                    interval={2500}
                    style={{
                        zIndex: 0
                    }}
                    onSlide={(index) => setIndex(index)}
                >
                    { _.map(new Array(pages.length), (item, index) => (
                        <Carousel.Item>
                            <div style={{
                                width: '100%',
                                height: '750px',
                                position: 'relative'
                            }}>
                                <div style={{
                                    width: '100%',
                                    height: '50%',
                                    float: 'left',
                                    backgroundImage: `url(${require(`../../assets/header-left-${index+1}.webp`)})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}/>
                                <div style={{
                                    width: '100%',
                                    height: '50%',
                                    float: 'right',
                                    backgroundImage: `url(${require(`../../assets/header-right-${index+1}.webp`)})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}/>
                            </div>
                        </Carousel.Item>
                    )) }
                </Carousel>

                {/* Gradient */}
                <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {/* Gradient from left to middle */}
                    <div style={{
                        width: '100%',
                        height: `calc(50% - 25px)`,
                        background: 'linear-gradient(to top, white, transparent)'
                    }}/>
                    {/* Middle background */}
                    <div style={{
                        width: '100%',
                        height: '50px',
                        background: 'white'
                    }}/>
                    {/* Gradient from right to middle */}
                    <div style={{
                        width: '100%',
                        height: `calc(50% - 25px)`,
                        background: 'linear-gradient(to bottom, white, transparent)'
                    }}/>
                </div>

                {/* Logo */}
                <div style={{
                    position: 'absolute',
                    top: '65px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <img 
                        src={require('../../assets/logo-whiteback.png')} 
                        alt="logo" 
                        width={'350px'}
                    />
                </div>

                {/* Content */}
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>

                    {/* Title */}
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <div style={{
                            width: '45%',
                            height: '45px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            position: 'relative'
                        }}>
                            { transitions((style, i) => {
                                return pages[i]({ style });
                            }) }	
                        </div>
                        <HeaderTitleLocationPart>
                            à Villefranche-sur-Saône
                        </HeaderTitleLocationPart>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div style={{
            width: '100%',
            height: '750px',
            position: 'relative',
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 20px 5px',
        }}>
            <Carousel
                fade 
                indicators={false}
                controls={false}
                interval={2500}
                style={{
                    zIndex: 0
                }}
                onSlide={(index) => setIndex(index)}
            >
                { _.map(new Array(pages.length), (item, index) => (
                    <Carousel.Item>
                        <div style={{
                            width: '100%',
                            height: '750px',
                            position: 'relative'
                        }}>
                            <div style={{
                                width: '50%',
                                height: '100%',
                                float: 'left',
                                backgroundImage: `url(${require(`../../assets/header-left-${index+1}.webp`)})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}/>
                            <div style={{
                                width: '50%',
                                height: '100%',
                                float: 'right',
                                backgroundImage: `url(${require(`../../assets/header-right-${index+1}.webp`)})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}/>
                        </div>
                    </Carousel.Item>
                )) }
            </Carousel>

            {/* Gradient */}
            <div style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
            }}>
                {/* Gradient from left to middle */}
                <div style={{
                    width: `calc(50% - 200px)`,
                    height: '100%',
                    background: 'linear-gradient(to left, white, transparent)'
                }}/>
                
                <div style={{
                    width: '400px',
                    height: '100%',
                    background: 'white'
                }}/>

                {/* Gradient from right to middle */}
                <div style={{
                    width: `calc(50% - 200px)`,
                    height: '100%',
                    background: 'linear-gradient(to right, white, transparent)'
                }}/>
                
            </div>

            {/* Content */}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                {/* Logo MondeDuMacaron */}
                <img 
                    src={require('../../assets/logo.png')} 
                    alt="logo" 
                    width={'200px'}
                />

                {/* Title */}
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '45px'
                }}>
                    <div style={{
                        width: '45%',
                        height: '45px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        position: 'relative'
                    }}>
                        { transitions((style, i) => {
                            return pages[i]({ style });
                        }) }	
                    </div>
                    <h1 style={{
                        width: '50%',
                        fontFamily: 'Birds',
                        fontWeight: 300,
                        fontSize: '1.8rem',
                        lineHeight: '45px',
                        margin: '0 auto',
                        padding: 0,
                        color: 'black',
                        marginTop: '0',
                        marginRight: '10px',
                        marginLeft: '10px',
                        paddingTop: '5px'
                    }}>
                        à Villefranche-sur-Saône
                    </h1>
                </div>
        
                <p style={{
                    margin: '0 auto',
                    textAlign: 'center',
                    fontFamily: 'Open Sans',
                    fontWeight: 300,
                    fontSize: '1rem',
                    marginTop: '35px',
                    padding: 0,
                    maxWidth: '500px'
                }}>
                    Plongez dans une atmosphère conviviale et raffinée, et laissez-vous séduire par une symphonie de saveurs.<br/>
                    Nous vous invitons à explorer notre vaste sélection de délices <b>sucrés</b> et <b>salés</b>, 
                    incluant des <b>macarons</b> aux arômes variés, des <b>pâtisseries</b> délicates, des <b>gaufres</b> croustillantes, 
                    des <b>crêpes</b> savoureuses, et bien d'autres <b>gourmandises</b> irrésistibles.
                </p>

            </div>
        </div>
    )
}

export default Header;