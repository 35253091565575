import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import styled from 'styled-components'
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareUpRight, faPhone, faAt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import mapStyle from './google-map-style';
import './home.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import useWindowDimensions from '../../modules/hooks/useWindowDimensions';
import Header from '../../modules/header';
import Navbar from '../../modules/navbar';
import Footer from '../../modules/footer';

const URL_CARTE = 'https://www.canva.com/design/DAFqR7GcTlk/vsYS9DyJhkRyS-wPJWibfw/edit';
const URL_FABRICATION = '/fabrication';

const MacaronCategoriesContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 50px;
	justify-content: space-between;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const MacaronCategoryContainer = styled.div`
	width: calc(50% - 10px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	animationDuration: 0.5s;

	&:hover {
		& > div {
			opacity: 0.5;
			cursor: pointer;
		}
	}

	@media (max-width: 768px) {
		width: calc(100% - 30px);
		flex-direction: row;
		margin-left: 15px;
		margin-bottom: 15px;
	}
`;

const MacaronCategory = ({ title, image, anchor }) => (
	<MacaronCategoryContainer>
		<a href={`/macarons/${anchor}`} style={{ 
			width: '100%',
			textDecoration: 'none',
		}}>
			<div style={{
				width: '100%',
				height: '300px',
				backgroundImage: `url(${image})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center bottom',
				borderRadius: '10px',
			}}>
				<h3 style={{
					fontFamily: "Open Sans",
					fontWeight: 800,
					color: 'white',
					textTransform: 'uppercase',
					fontSize: '31px',
					margin: 0,
					lineHeight: '25px',
					transform: 'rotate(-90deg)',
					position: 'absolute',
					right: '-75px',
					top: '100px',
					height: '25px',
					width: '200px',
					textAlign: 'right',
					letterSpacing: '3px'
				}}>
					{ title }
				</h3>
			</div>
			<div style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'flex-end',
				marginTop: '5px',
			}}>	
				<span style={{
					fontFamily: 'Open Sans',
					fontWeight: 400,
					textTransform: 'uppercase',
					letterSpacing: '0.5px',
					fontSize: '0.8rem',
					color: '#111111',
					marginRight: '7px'
				}}>
					Découvrir
				</span>
				<FontAwesomeIcon icon={faSquareUpRight} size={'1x'} color={'#111111'} />
			</div>
		</a>
	</MacaronCategoryContainer>
);

const Home = () => {

	const { width } = useWindowDimensions();
	const headerRef = React.useRef(null);
	const nosMacaronsRef = React.useRef(null);
	const fabricationRef = React.useRef(null);
	const nosCartesRef = React.useRef(null);
	const evenementielRef = React.useRef(null);
	const gallerieRef = React.useRef(null);
	const contactRef = React.useRef(null);


	const handleScrollTo = (ref) => {
		ref?.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDyVJEIPPd1p-czk860HBY0xb7F3tQK3pY'
	});

	const menuItems = [
		{
			title: 'Accueil',
			onClick: () => handleScrollTo(headerRef)
		},
		{
			title: 'Nos macarons',
			onClick: () => handleScrollTo(nosMacaronsRef)
		},
		{ title: 'Fabrication', onClick: () => handleScrollTo(fabricationRef) },
		{
			title: 'Nos cartes',
			onClick: () => handleScrollTo(nosCartesRef)
		},
		{
			title: 'Evenementiel',
			onClick: () => handleScrollTo(evenementielRef)
		},
		{
			title: 'Gallerie',
			onClick: () => handleScrollTo(gallerieRef)
		},
		{ title: 'Macaron IA', onClick: () => window.location.href = '/macaron-ia' },
		{
			title: 'Contact',
			onClick: () => handleScrollTo(contactRef)
		}
	];

	return (
		<div className="App">

			{/* Menu */}
			<Navbar menuItems={menuItems} />

			<div ref={headerRef} style={{
				width: '100%',
				opacity: 0,
				top: '-60px',
			}} />

			{/* Header */}
			<Header />

			{/* Nos macarons */}
			<section className='nos-macarons' style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				width: '100%',
				borderTop: '3px solid #db4a94',
				position: 'relative',
			}}>
				<div ref={nosMacaronsRef} style={{
					width: '100%',
					opacity: 0,
					top: '-60px',
					position: 'absolute'
				}} />
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: '60px',
					paddingBottom: '80px',
				}}>

					<img 
						src={require('../../assets/title-macarons.png')} 
						alt="title" 
						width={'75px'}
					/>

					<h1 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '2.2rem',
						margin: '0 auto',
						padding: 0,
						color: 'black',
						marginTop: '15px'
					}}>
						Nos Macarons
					</h1>

					<h2 style={{
						fontFamily: 'Open Sans',
						fontWeight: 300,
						fontSize: '0.9rem',
						margin: 0,
						padding: 0,
						color: 'rgb(255, 128, 171)',
						marginTop: 0,
						textTransform: 'uppercase',
						letterSpacing: '2px'
					}}>
						traditionnels et originaux
					</h2>

					<article style={{
						margin: '0 auto',
						textAlign: 'center',
						fontFamily: 'Open Sans',
						fontWeight: 300,
						fontSize: '1rem',
						marginTop: '35px',
						padding: '0 20px',
						maxWidth: '900px'
					}}>
						Découvrez l'harmonie parfaite entre délicatesse et saveurs audacieuses avec notre collection exquise de macarons.
						Chaque bouchée est une invitation sensorielle, alliant la légèreté de la coque croustillante à des ganaches fondantes et des crèmes subtilement parfumées.
						Des classiques intemporels aux créations innovantes, nos macarons sont confectionnés avec soin pour éveiller vos papilles.
					</article>
					
					<MacaronCategoriesContainer>
						<MacaronCategory
							title='Sucrés'
							image={require('../../assets/sweet-macarons.webp')}
							anchor='sucres'
						/>
						<MacaronCategory
							title='Salés'
							image={require('../../assets/savory-macarons.webp')}
							anchor='sucres'
						/>
					</MacaronCategoriesContainer>

					{/* La fabrication */}
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						marginTop: '60px',
						paddingLeft: '15px',
						paddingRight: '15px',
						position: 'relative'
					}}>
						<div ref={fabricationRef} style={{
							width: '100%',
							opacity: 0,
							top: '-80px',
							position: 'absolute'
						}} />
						<img 
							alt='separator-france'
							src={require('../../assets/separator-france.png')} 
							width='200px' 
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px 5px',
							}}
						/>
						<h3 style={{
							fontFamily: 'Birds',
							color: 'black',
							margin: 0,
							lineHeight: '25px',
							fontSize: '1.5em',
							marginTop: '50px',
						}}>
							La fabrication
						</h3>
						<p style={{
							textAlign: 'center',
							fontFamily: 'Open Sans',
							fontWeight: 300,
							fontSize: '1rem',
							maxWidth: '900px',
							marginTop: '20px',
							marginBottom: 0
						}}>
							Nous sommes fiers de proposer des produits conçus et fabriqués dans nos ateliers français. 
							Découvrez notre savoir-faire artisanal et notre passion pour la pâtisserie à travers la fabrication de nos macarons.
						</p>
						<button 
							style={{
								background: '#e4067e',
								color: 'white',
								border: 'none',
								fontFamily: 'Open Sans',
								fontWeight: 700,
								fontSize: '0.9rem',
								padding: '10px 20px',
								marginTop: '25px',
								cursor: 'pointer',
								borderRadius: '25px'
							}}
							onClick={() => window.location.href = URL_FABRICATION }
						>
							En savoir plus
						</button>
					</div>
						
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						marginTop: '60px'
					}}>
						<img 
							alt='separator-france-flag'
							src={require('../../assets/separator-custom.png')} 
							width='200px' 
						/>
						<span style={{
							fontFamily: 'Birds',
							color: 'black',
							margin: 0,
							lineHeight: '25px',
							fontSize: '1.5em',
							marginTop: '45px',
						}}>
							Découvrez également
						</span>
						<span style={{
							fontFamily: "Open Sans",
							color: 'rgb(255, 128, 171)',
							fontSize: '1.5em',
							margin: 0,
							lineHeight: '25px',
							fontWeight: 300,
							textTransform: 'uppercase',
							letterSpacing: '4px',
							marginTop: '5px',
							marginBottom: '15px',
							textAlign: 'center',
							paddingLeft: '15px',
							paddingRight: '15px'
						}}>
							notre sélection du moment
						</span>
					</div>
			
					{ width >= 768 && (
						<div style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							marginTop: '25px',
							justifyContent: 'space-between',
						}}>
							{ _.map(new Array(5), (item, index) => (
								<div key={`item-promo-${index}`} style={{
									width: 'calc(20% - 20px)',
									height: '300px',
									backgroundImage: `url(${require(`../../assets/promotions/${index+1}.webp`)})`,
									backgroundSize: '107%',
									backgroundPosition: 'center',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'relative',
									borderRadius: '10px',
								}}/>
							))}
						</div>
					)}

					{ width < 768 && (
						<Carousel 
							style={{
								width: '100%',
								marginTop: '25px',
								paddingLeft: '15px',
								paddingRight: '15px'
							}}
						>
							{ _.map(new Array(5), (item, index) => (
								<Carousel.Item>
									<div key={`item-promo-${index}`} style={{
										width: '100%',
										height: '300px',
										backgroundImage: `url(${require(`../../assets/promotions/${index+1}.webp`)})`,
										backgroundSize: '107%',
										backgroundPosition: 'center',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										position: 'relative',
										borderRadius: '10px',
									}}/>
								</Carousel.Item>
							))}
						</Carousel>
					)}

				</div>
			</section>

			{/* Nos cartes */}
			<section style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				backgroundImage: `url(${require('../../assets/texture-sweets.jpg')})`,
				backgroundSize: '10%',
				borderBottom: '3px solid #db4a94',
				borderTop: '3px solid #db4a94',
				boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 20px 5px',
				position: 'relative'
			}}>
				<div ref={nosCartesRef} style={{
					width: '100%',
					opacity: 0,
					top: '-60px',
					position: 'absolute'
				}} />
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: width > 768 ? 'row' : 'column',
					paddingTop: '60px',
					paddingBottom: '60px',
				}}>

					<div style={{
						width: width > 768 ? '30%' : '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'flex-start'
					}}>
						<h3 style={{
							width: '100%',
							fontFamily: 'Birds',
							fontWeight: 300,
							fontSize: '2.2rem',
							textAlign: width > 768 ? 'left' : 'center',
							margin: 0,
							color: '#3e3e42'
						}}>
							Nos Boissons
						</h3>
						<ul	style={{
							fontFamily: 'Open Sans',
							fontWeight: 300,
							fontSize: '0.9rem',
							margin: 0,
							padding: 0,
							color: 'black',
							marginTop: '15px',
							textAlign: width > 768 ? 'left' : 'center',
							listStyle: 'none',
							paddingLeft: '15px',
							borderLeft: width > 768 ? '5px solid rgb(255, 128, 171)' : 'none',
							width: '100%'
						}}>
							<li>Cafés-Nespresso</li>
							<li>Formules salon et thé</li>
							<li>Cafés / Chocolats gourmands</li>
							<li>Boisson fraîches</li>
							<li>Smoothies / Milshakes</li>
							<li>Thés</li>
							<li>Bubble Teas</li>
						</ul>
					</div>

					<div style={{
						width: width > 768 ? '40%' : '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: width > 768 ? '-10px' : '20px'
					}}>
						<img src={require('../../assets/top-title-spikes.png')} alt="title" width={'70px'} />
						<img src={require('../../assets/menu-cartes.png')} alt="menu-cartes" width={'320px'} style={{
							marginTop: '35px'
						}} />
						<button 
							style={{
								background: '#e4067e',
								color: 'white',
								border: 'none',
								fontFamily: 'Open Sans',
								fontWeight: 700,
								fontSize: '0.9rem',
								padding: '10px 20px',
								marginTop: '35px',
								cursor: 'pointer',
								borderRadius: '25px'
							}} 
							onClick={() => window.location.href = URL_CARTE }
						>
							Consulter notre carte
						</button>
					</div>

					<div style={{
						width: width > 768 ? '30%' : '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'flex-start'
					}}>
						<h3 style={{
							fontFamily: 'Birds',
							fontWeight: 300,
							fontSize: '2.2rem',
							textAlign: width > 768 ? 'right' : 'center',
							margin: 0,
							width: '100%',
							color: '#3e3e42',
							marginTop: width > 768 ? 0 : '40px'
						}}>
							Nos Gourmandises
						</h3>
						
						<div style={{
							paddingRight: '15px',
							borderRight: width > 768 ? '5px solid rgb(255, 128, 171)' : 'none',
							width: '100%',
							marginTop: '15px',
						}}>
							<h6 style={{
								fontFamily: 'Open Sans',
								fontWeight: 700,
								fontSize: '0.9rem',
								margin: 0,
								padding: 0,
								color: '#e4067e',
								textAlign: width > 768 ? 'right' : 'center',
								width: '100%',
								textTransform: 'uppercase'
							}}>
								Sucrées
							</h6>
							<ul	style={{
								width: '100%',
								fontFamily: 'Open Sans',
								fontWeight: 300,
								fontSize: '0.9rem',
								margin: 0,
								padding: 0,
								color: 'black',
								marginTop: '5px',
								textAlign: width > 768 ? 'right' : 'center',
								listStyle: 'none'
							}}>
								<li>Pâtisseries</li>
								<li>Crêpes / Gauffres</li>
								<li>Glaces</li>
							</ul>

							<h6 style={{
								fontFamily: 'Open Sans',
								fontWeight: 700,
								fontSize: '0.9rem',
								margin: 0,
								padding: 0,
								color: '#e4067e',
								marginTop: '15px',
								textAlign: width > 768 ? 'right' : 'center',
								width: '100%',
								textTransform: 'uppercase'
							}}>
								Salées
							</h6>
							<ul	style={{
								width: '100%',
								fontFamily: 'Open Sans',
								fontWeight: 300,
								fontSize: '0.9rem',
								margin: 0,
								padding: 0,
								color: 'black',
								marginTop: '5px',
								textAlign: width > 768 ? 'right' : 'center',
								listStyle: 'none'
							}}>
								<li>Snacking</li>
								<li>Sunny Pockets</li>
								<li>Quiches</li>
								<li>Plats en bocaux</li>
							</ul>
						</div>
					</div>

				</div>
			</section>

			{/* Evenements */}
			<section style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				height: width > 768 ? '380px' : 'auto',
				position: 'relative'
			}}>
				<div ref={evenementielRef} style={{
					width: '100%',
					opacity: 0,
					top: '-60px',
					position: 'absolute'
				}} />
				<div style={{
					width: '100%',
					maxWidth: '1080px',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: '60px',
					paddingBottom: '60px',
				}}>

					<h2 style={{
						fontFamily: 'Open Sans',
						fontWeight: 300,
						fontSize: '2rem',
						textAlign: 'center',
						textTransform: 'uppercase',
						letterSpacing: '7px',
						margin: 0,
						color: '#FF80AB',
						width: '100%',
					}}>
						Evenementiel
					</h2>

					<h3 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '1.3rem',
						textAlign: 'center',
						margin: 0,
						color: '#3e3e42',
						width: '100%',
					}}>
						particuliers ou professionnels
					</h3>

					<div style={{
						display: 'flex',
						flexDirection: width > 768 ? 'row' : 'column',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						marginTop: '35px'
					}}>
						<div style={{
							width: width > 768 ? '50%' : '100%',
							display: 'flex',
							flexDirection: width > 768 ? 'row-reverse' : 'column',
							justifyContent: 'flex-start',
							alignItems: width > 768 ? 'flex-start' : 'center'
						}}>
							<div style={{
								width: '200px',
								height: '300px',
								backgroundImage: `url(${require('../../assets/evenement-particuliers.webp')})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center top',
								borderRadius: '10px',
								border: '5px solid white',
								zIndex: 100
							}} />
							<p style={{
								textAlign: width > 768 ? 'right' : 'center',
								paddingRight: '15px',
								paddingLeft: '15px',
								paddingTop: '15px',
								width: width > 768 ? 'calc(100% - 200px)' : '100%',
								fontFamily: 'Open Sans',
								fontWeight: 300,
							}}>
								Découvrez notre nouvelle offre exclusive de <b>macarons personnalisés</b> !<br/>
								Offrez une touche unique à vos événements spéciaux en personnalisant nos délicieux macarons avec l’impression de votre choix.
							</p>
						</div>
						
						<div style={{
							width: width > 768 ? '50%' : '100%',
							display: 'flex',
							flexDirection: width > 768 ? 'row' : 'column',
							justifyContent: 'flex-start',
							alignItems: width > 768 ? 'flex-start' : 'center'
						}}>
							<div style={{
								width: '200px',
								height: '300px',
								backgroundImage: `url(${require('../../assets/evenement-entreprise.webp')})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center top',
								borderRadius: '10px',
								border: '5px solid white',
								marginLeft: '10px',
								zIndex: 100
							}} />
							<p style={{
								textAlign: width > 768 ? 'left' : 'center',
								paddingLeft: '15px',
								paddingTop: '15px',
								width: 'calc(100% - 200px)',
								fontFamily: 'Open Sans',
								fontWeight: 300,
							}}>
								Impressionnez vos convives avec des <b>pyramides de macarons</b> au goût du chocolat, citron, pistache, framboise...<br/>
								Disponibles et à votre écoute, nous saurons vous accompagner dans le choix du dessert idéal pour votre soirée.
							</p>
						</div>
					</div>

				</div>
			</section>

			{/* Gallerie */}
			<section style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				backgroundColor: '#db4a94',
				position: 'relative'
			}}>
				<div ref={gallerieRef} style={{
					width: '100%',
					opacity: 0,
					top: '20px',
					position: 'absolute'
				}} />
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: width > 768 ? '120px' : '60px',
					paddingBottom: '80px',
					paddingLeft: '20px',
					paddingRight: '20px',
				}}>
			
					<h1 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '2.2rem',
						margin: '0 auto',
						padding: 0,
						color: 'white',
					}}>
						Gallerie photos
					</h1>
			
					<div style={{
						width: '100%',
						display: 'flex',
						flexDirection: width > 768 ? 'row' : 'column',
						marginTop: '50px',
						justifyContent: 'space-between',
					}}>
						<button 
							style={{
								width: width > 768 ? 'calc((100% / 3) - 10px)' : '100%',
								marginBottom: width > 768 ? 0 : '15px',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'relative',
								border: 'none',
								background: 'transparent'
							}} 
							onClick={() => window.location.href = '/gallerie' }
						>
							<div style={{
								width: '100%',
								height: '500px',
								backgroundImage: `url(${require('../../assets/gallerie-categorie-produits.webp')})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center bottom',
								borderRadius: '10px',
								border: '3px solid white'
							}} />
							<h3 style={{
								fontFamily: "Open Sans",
								fontWeight: 800,
								color: 'white',
								textTransform: 'uppercase',
								fontSize: '20px',
								margin: 0,
								lineHeight: '25px',
								transform: 'rotate(-90deg)',
								position: 'absolute',
								right: '-75px',
								top: '100px',
								height: '25px',
								width: '200px',
								textAlign: 'right',
								letterSpacing: '2px'
							}}>
								Produits
							</h3>
						</button>
							
						<button 
							style={{
								width: width > 768 ? 'calc((100% / 3) - 10px)' : '100%',
								marginBottom: width > 768 ? 0 : '15px',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'relative',
								border: 'none',
								background: 'transparent'
							}} 
							onClick={() => window.location.href = '/gallerie' }
						>
							<div style={{
								width: '100%',
								height: '500px',
								backgroundImage: `url(${require('../../assets/gallerie-categorie-salon.webp')})`,
								backgroundSize: 'cover',
								backgroundPosition: '30% bottom',
								borderRadius: '10px',
								border: '3px solid white'
							}} />
							<h3 style={{
								fontFamily: "Open Sans",
								fontWeight: 800,
								color: 'white',
								textTransform: 'uppercase',
								fontSize: '20px',
								margin: 0,
								lineHeight: '25px',
								transform: 'rotate(-90deg)',
								position: 'absolute',
								right: '-75px',
								top: '100px',
								height: '25px',
								width: '200px',
								textAlign: 'right',
								letterSpacing: '2px'
							}}>
								Salon
							</h3>
						</button>

						<button 
							style={{
								width: width > 768 ? 'calc((100% / 3) - 10px)' : '100%',
								marginBottom: width > 768 ? 0 : '15px',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'relative',
								border: 'none',
								background: 'transparent'
							}} 
							onClick={() => window.location.href = '/gallerie' }
						>
							<div style={{
								width: '100%',
								height: '500px',
								backgroundImage: `url(${require('../../assets/gallerie-categorie-evenements.webp')})`,
								backgroundSize: 'cover',
								backgroundPosition: 'bottom',
								borderRadius: '10px',
								border: '3px solid white'
							}} />
							<h3 style={{
								fontFamily: "Open Sans",
								fontWeight: 800,
								color: 'white',
								textTransform: 'uppercase',
								fontSize: '20px',
								margin: 0,
								lineHeight: '25px',
								transform: 'rotate(-90deg)',
								position: 'absolute',
								right: '-75px',
								top: '100px',
								height: '25px',
								width: '200px',
								textAlign: 'right',
								letterSpacing: '2px'
							}}>
								Evenements
							</h3>
						</button>

					</div>

					<div style={{
						display: 'flex',
						alignContent: 'center',
						justifyContent: 'center',
						marginTop: '35px',
					}}>
						<button 
							style={{
								background: '#111111',
								color: 'white',
								border: 'none',
								fontFamily: 'Open Sans',
								fontWeight: 700,
								fontSize: '0.9rem',
								padding: '10px 25px',
								cursor: 'pointer',
								borderRadius: '25px'
							}}
							onClick={() => window.location.href = '/gallerie' }
						>
							Parcourir la gallerie
						</button>
					</div>

				</div>
			</section>

			{/* Carte */}
			<section style={{
				display: 'flex',
				width: '100%',
				height: '500px',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				borderTop: '3px solid white',
				borderBottom: '3px solid white',
				boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 20px 5px',
			}}>
				{ isLoaded ? (
					<GoogleMap
						mapContainerStyle={{
							width: '100%',
							height: '100%'
						}}
						center={{
							lat: 45.98752457302255,
							lng: 4.718425442329277
						}}
						zoom={14}
						options={{
							styles: mapStyle
						}}
					>
						<Marker
							position={{
								lat: 45.98752457302255,
								lng: 4.718425442329277
							}}
						>
							<div style={{
								width: '30px',
								height: '30px',
								backgroundColor: 'red'
							}} />
						</Marker>
					</GoogleMap>
				) : <p>Loading map ...</p> }
			</section>

			{/* Contact */}
			<section style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				backgroundColor: '#303031',
				position: 'relative'
			}}>
				<div ref={contactRef} style={{
					width: '100%',
					opacity: 0,
					top: '0',
					position: 'absolute'
				}} />
				<div style={{
					width: '100%',
					maxWidth: '1200px',
					height: '100%',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					flexDirection: 'column',
					paddingTop: '60px',
					paddingBottom: '60px',
				}}>

					<h1 style={{
						fontFamily: 'Birds',
						fontWeight: 300,
						fontSize: '2.2rem',
						margin: '0 auto',
						padding: 0,
						color: 'white',
					}}>
						Contact
					</h1>


					<div style={{
						marginTop: '25px'
					}}>
						<p style={{
							margin: 0,
							padding: 0,
							fontWeight: 300,
							textAlign: 'center',
							fontFamily: 'Open Sans',
							color: 'white',
						}}>
							Le Monde du Macaron<br/>
							885 Rue nationale<br/>
							69400 Villefranche-sur-Saône
						</p>
					</div>

					<div style={{
						marginTop: '25px'
					}}>
						<p style={{
							margin: 0,
							padding: 0,
							fontWeight: 300,
							textAlign: 'center',
							fontFamily: 'Open Sans',
							color: 'white',
						}}>
							<FontAwesomeIcon icon={faPhone} style={{ marginRight: '10px' }} />
							<span>+33 (0)4 74 67 68 35</span>
							<br/>
							<FontAwesomeIcon icon={faAt} style={{ marginRight: '10px' }} />
							<span>
								<a href="mailto:lmdm.villefranche@gmail.com" style={{
									color: 'white',
									textDecoration: 'none'
								}}>
									lmdm.villefranche@gmail.com
								</a>
							</span>
						</p>
					</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						marginTop: '50px'
					}}>

						{ width > 768 && (
							<img 
								src={require('../../assets/logo-negatif.png')} 
								width='150px'
								alt='logo-negatif'
							/>
						)}

						<div style={{
							borderLeft: width > 768 ? '3px solid white' : 'none',
							textAlign: width > 768 ? 'left' : 'center',
							paddingLeft: width > 768 ? '45px' : '15px',
							paddingRight: width > 768 ? 0 : '15px',
							marginLeft: width > 768 ? '45px' : 0,
						}}>
							<article style={{
								fontFamily: 'Open Sans',
								fontWeight: 300,
								color: 'white',
								margin: 0,
								padding: 0,
								fontSize: '1rem'
							}}>
								<p style={{
									margin: 0
								}}>
									<span style={{
										fontWeight: 700,
										color: 'white'
									}}>
										Horaires d'ouvertures
									</span>
									<br />
									<div style={{
										display: 'flex',
										flexDirection: 'column',
										marginTop: '5px'
									}}>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Lundi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Mardi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Mercredi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Jeudi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Vendredi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
										<div style={{
											display: 'flex',
											flexDirection: 'row'
										}}>
											<span className='day'>Samedi</span>
											<span className='horaire'>08:30 – 19:00</span>
										</div>
									</div>
								</p>
							</article>
						</div>

					</div>

					<div style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-evenly',
						alignItems: 'center',
						width: '100%',
						maxWidth: '300px',
						marginTop: '35px'
					}}>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.facebook.com/profile.php?id=100083022027611' }
						>
							<FontAwesomeIcon icon={faFacebookF} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.instagram.com/lemondedumacaron.69400/' }
						>
							<FontAwesomeIcon icon={faInstagram} style={{
								color: 'white',
								fontSize: '1.3rem',
								
							}} />
						</button>
						<button 
							style={{
								background: 'black',
								border: 'none',
								borderRadius: '50px',
								height: '50px',
								width: '50px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={() => window.location.href = 'https://www.tiktok.com/@lemondedumacaron.69400' }
						>
							<FontAwesomeIcon icon={faTiktok} style={{
								color: 'white',
								fontSize: '1.3rem',
							}} />
						</button>
					</div>

				</div>
			</section>


			{/* Footer */}
			<Footer />

		</div>
	);
}

export default Home;
